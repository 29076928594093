import { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

export function Header() {
    const [clicked, setClicked] = useState(false);

    function handleLinkClick() {
        setClicked(false);
    }

    return <div className="header-container">
        <div className="header-body">
            <div className="header-logo">
                <Link onClick={handleLinkClick} to="/">NIKATAX</Link>
            </div>
            <div className="header-links">
            </div>
            <div className={`header-additional${clicked ? " active-links" : ""}`}>
                <a onClick={handleLinkClick} href="/#about">Über uns</a>
                <a onClick={handleLinkClick} href="/#why">Wieso wir</a>
                <a onClick={handleLinkClick} href="/#contact">Kontakt</a>
            </div>
            <div onClick={() => setClicked(!clicked)} className={`burger${clicked ? " active" : ""}`}>
                <div className="line1" />
                <div className="line2" />
                <div className="line3" />
            </div>
        </div>
    </div>
}

export default Header;