import React from 'react';
import taxes from '../images/taxes.svg';
// import person from '../images/da.jpg';
import '../styles/Home.css';
import { Link } from 'react-router-dom';
import { Contact } from '../components';

export function Home() {
    return <div className="home container">
        <div className="home-main">
            <div>
                <img src={taxes} alt="An illustration of taxes" />
                <div>
                    <h1>Wir machen Steuern einfach</h1>
                    <p>Keine lust auf Steuerkram? Kein problem, dafür sind wir da!</p>
                </div>
            </div>
        </div>
        <div className="home-intro">
            <div id="about" className="home-about">
                {/* <div>
                    <img src={person} alt="An illustration of taxes" />
                </div> */}
                <div>
                    <h2>Wer sind wir?</h2>
                    <p>Wir sind nikatax. Ich – als Gründerin von nikatax – bin Studentin im letzten Bachelorjahr und studiere die Fächer Deutsch, RZG (Geografie und Geschichte) und ERG (Ethik, Religionen und Gesellschaften). Mein Ziel ist es, Lehrerin der Sekundarstufe I zu werden. Nebenbei arbeite ich als Deutschlehrerin mit einem Pensum von 20%. Seit ich 18 Jahre alt bin, fülle ich die Steuererklärungen meines Umkreises aus. Jährlich sind dies ca. 40 – 50 Steuererklärungen, die ich ausfülle. </p>
                </div>
            </div>
            <div id="why" className="home-why">
                <div>
                    <h2>Wieso bei nikatax Ihre Steuererklärungen ausfüllen lassen?</h2>
                    <p>Jährlich teilt Ihnen die Steuerverwaltung in einem Brief mit, dass Sie die Steuererklärung ausfüllen müssen. Mit der Steuererklärung wird ermittelt, wie viel Einkommens- und Vermögenssteuer Sie bezahlen müssen.
                        Nikatax nimmt Ihnen viel Arbeit weg. Unsere Webseite ist so gestaltet, dass Sie keine Unterlagen per Post oder persönlich einreichen müssen. Alles geschieht elektronisch. Die Gründerin von nikatax füllt Ihre Steuererklärung innerhalb von 3 – 5 Werktagen aus und sendet Ihnen den Beleg, den Sie unterschreiben sollen, entweder elektronisch oder per Post ab. Die Vorteile von nikatax:</p>
                    <ul>
                        <li>Alles elektronisch</li>
                        <li>Schnell und diskret</li>
                        <li>Falls Sie doch alles auf Papier möchten, können Sie sich per Mail melden und mir alles per Post zuschicken.</li>
                        <li>Versandkosten werden von mir übernommen.</li>
                        <li>Günstig: nur CHF 50.-</li>
                        <li>Es wird Ihnen viel Arbeit für wenig Geld weggenommen.</li>
                    </ul>
                </div>
            </div>
            <Contact />
        </div>
        <Link className='home-link' to="/tax">Steuern einreichen</Link>
    </div>

}

export default Home;