import moment from 'moment';
import '../styles/Input.css';

export function Input({ label, value, onChange, type, required, disabled, error }) {
    function getMin() {
        if (type === "date") {
            return moment().format("YYYY-MM-DD")
        }
    }

    function getMax() {
        if (type === "date") {
            return moment().subtract(120, 'years').format("YYYY-MM-DD")
        }
    }

    return (
        <div className="form-container">
            <label>{label}</label>
            {type === "textarea" ?
                <textarea
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    value={value}
                ></textarea>
                : <input
                    max={getMin()}
                    min={getMax()}
                    value={value}
                    onChange={onChange}
                    type={type}
                    required={required}
                    disabled={disabled}
                />}
            <span>{error}</span>
        </div>
    );
}

export default Input;