import '../styles/Footer.css'

export default function Footer() {
    return <div className='footer-container'>
        <div className="footer-body">
            <div className="footer-intro">
                <h2 className="footer-title">NIKATAX</h2>
                <p>Nikatax ist ein professioneller Anbieter, <br />der Ihre Steuern für Sie ausfüllt.<br />Der ganze Vorgang folgt elektronisch ab.</p>
            </div>
            <div className="footer-links">
                <h3 className="footer-title">Links</h3>
                <div>
                    <a href="#about">Über uns</a>
                    <a href="#why">Wieso wir?</a>
                    <a href="#contact">Kontakt</a>
                </div>
            </div>
        </div>
        <div className='footer-copyright'>
            <div className="footer-copyright-body">
                <span>Copyright &copy; {new Date().getFullYear()} Nikatax.ch. Alle Rechte vorbehalten</span>
                <div>
                    <a href="instagram.com">I</a>
                </div>
            </div>
        </div>
    </div>
}