import React, { useEffect, useState } from 'react';

// materialui
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import '../styles/Formluar.css';
import { CheckMarkSuccess, FileDrop, Input, Select, Loading, ApiClient } from '../components';
import { Personals } from '../components/Personals';
import { useToasts } from 'react-toast-notifications';
import { WHITELIST } from '../constants/whitelist';

const steps = ['Eingabe Personalien', 'Auswahl Dokumente', 'Abschluss'];

export function Formular() {
    let { addToast } = useToasts();

    const [progress, setProgress] = useState(0);
    const [personals, setPersonals] = useState({
        firstname: "",
        lastname: "",
        email: "",
        birthday: "",
        phoneNumber: "",
        address: "",
        canton: "",
        place: "",
        postalCode: 0,
        profession: "",
        workStatus: "arbeitnehmer",
        civilStatus: "ledig",
        partner: {},
        hasChildren: "nein",
        children: [
            {
                fullName: "",
                birthday: "",
                inApprenticeship: "nein",
            }
        ]
    });
    const [files, setFiles] = useState({
        medicalCosts: "0",
        professionalCosts: "0",
        trainingAndRetrainingCosts: "0",
    });
    const [wageStatements, setWageStatements] = useState(null);
    const [accounting, setAccounting] = useState(null);
    const [pensionStatements, setPensionStatements] = useState(null);
    const [bankStatements, setBankStatements] = useState(null);
    const [securitiesStatements, setSecuritiesStatements] = useState(null);
    const [voluntaryProvision, setVoluntaryProvision] = useState(null);
    const [donationStatements, setDonationStatements] = useState(null);
    const [homeownerStatements, setHomeownerStatements] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [isSendingSuccess, setIsSendingSuccess] = useState(false);
    const [errors, setErrors] = useState();

    function getDisableNext() {
        let isDisableNext = false;

        switch (progress) {
            case 0:
                if (
                    !personals.firstname ||
                    !personals.lastname ||
                    !personals.email ||
                    !personals.birthday ||
                    !personals.phoneNumber ||
                    !personals.address ||
                    !personals.canton ||
                    !personals.place ||
                    !personals.postalCode ||
                    personals.postalCode === 0 ||
                    !personals.profession ||
                    !personals.workStatus ||
                    !personals.civilStatus
                ) {
                    isDisableNext = true
                }
                if (
                    (isDisableNext === false && personals.civilStatus === "verheiratet") && (
                        !personals.partner ||
                        !personals.partner.firstname ||
                        !personals.partner.lastname ||
                        !personals.partner.birthday ||
                        !personals.partner.profession
                    )
                ) {
                    isDisableNext = true
                }
                break;
            case 1:
                if (

                    !bankStatements ||
                    bankStatements?.length === 0 ||
                    !securitiesStatements ||
                    securitiesStatements?.length === 0 ||
                    !voluntaryProvision ||
                    voluntaryProvision?.length === 0 ||
                    !donationStatements ||
                    donationStatements?.length === 0 ||
                    !files.medicalCosts ||
                    !files.professionalCosts ||
                    !files.trainingAndRetrainingCosts
                ) {
                    isDisableNext = true
                }
                if (
                    (isDisableNext === false && personals.workStatus === "arbeitnehmer") && (
                        !wageStatements ||
                        wageStatements?.length === 0
                    )
                ) {
                    isDisableNext = true
                } else if (
                    (isDisableNext === false && personals.workStatus === "selbststaendig") && (
                        !accounting ||
                        accounting?.length === 0
                    )
                ) {
                    isDisableNext = true
                } else if (
                    (isDisableNext === false && personals.workStatus === "pensioniert") && (
                        !pensionStatements ||
                        pensionStatements?.length === 0
                    )
                ) {
                    isDisableNext = true
                }
                break;
            default:
                isDisableNext = false;
                break;
        }
        return isDisableNext;
    }

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    function handleSend() {
        setIsSending(true);
        let error = {};
        if (
            !personals?.firstname ||
            personals?.firstname?.length > 150 ||
            typeof personals?.firstname !== "string" ||
            !WHITELIST.test(personals?.firstname)
        ) {
            error["firstname"] = {
                error:
                    "Bitte geben Sie einen validen Vornamen ein. Maximale länge beträgt 150 Zeichen!",
            };
        }
        if (
            !personals?.lastname ||
            personals?.lastname?.length > 150 ||
            typeof personals?.lastname !== "string" ||
            !WHITELIST.test(personals?.lastname)
        ) {
            error["lastname"] = {
                error:
                    "Bitte geben Sie einen validen Nachnamen ein. Maximale länge beträgt 150 Zeichen!",
            };
        }
        if (
            !personals?.email ||
            personals?.email?.length > 150 ||
            typeof personals?.email !== "string" ||
            !WHITELIST.test(personals?.email)
        ) {
            error["email"] = {
                error:
                    "Bitte geben Sie eine valide E-Mail-Adresse ein. Maximale länge beträgt 150 Zeichen!",
            };
        }
        if (
            !personals?.birthday ||
            personals?.birthday?.length > 12 ||
            !isValidDate(personals?.birthday) ||
            !WHITELIST.test(personals?.birthday)
        ) {
            error["birthday"] = {
                error:
                    "Bitte geben Sie ein valides Geburtdatum ein.",
            };
        }
        if (
            !personals?.phoneNumber ||
            personals?.address?.phoneNumber > 12 ||
            typeof personals?.phoneNumber !== "string" ||
            !WHITELIST.test(personals?.phoneNumber)
        ) {
            error["phoneNumber"] = {
                error:
                    "Bitte geben Sie eine valide Telefonnummer ein. Maximale länge beträgt 12 Zeichen!",
            };
        }
        if (
            !personals?.address ||
            personals?.address?.length > 250 ||
            typeof personals?.address !== "string" ||
            !WHITELIST.test(personals?.address)
        ) {
            error["address"] = {
                error:
                    "Bitte geben Sie eine valide Adresse ein. Maximale länge beträgt 250 Zeichen!",
            };
        }
        if (
            !personals?.canton ||
            personals?.address?.canton > 50 ||
            typeof personals?.canton !== "string" ||
            !WHITELIST.test(personals?.canton)
        ) {
            error["canton"] = {
                error:
                    "Bitte geben Sie einen validen Kanontsnamen ein. Maximale länge beträgt 50 Zeichen!",
            };
        }
        if (
            !personals?.place ||
            personals?.address?.place > 50 ||
            typeof personals?.place !== "string" ||
            !WHITELIST.test(personals?.place)
        ) {
            error["place"] = {
                error:
                    "Bitte geben Sie einen validen Ort ein. Maximale länge beträgt 50 Zeichen!",
            };
        }
        if (
            !personals?.postalCode ||
            personals?.address?.postalCode < 0 ||
            typeof personals?.place !== "number" ||
            !WHITELIST.test(personals?.place)
        ) {
            error["postalCode"] = {
                error:
                    "Bitte geben Sie eine valide PLZ ein.",
            };
        }
        if (
            !personals?.profession ||
            personals?.address?.profession > 75 ||
            typeof personals?.profession !== "string" ||
            !WHITELIST.test(personals?.profession)
        ) {
            error["profession"] = {
                error:
                    "Bitte geben Sie einen validen Beruf ein. Maximale länge beträgt 75 Zeichen!",
            };
        }
        if (
            !personals?.workStatus ||
            personals?.address?.workStatus > 15 ||
            typeof personals?.workStatus !== "string" ||
            !WHITELIST.test(personals?.workStatus) ||
            !["arbeitnehmer", "selbststaendig", "pensioniert"].includes(personals?.workStatus)
        ) {
            error["workStatus"] = {
                error:
                    "Bitte geben Sie einen valide Arbeitsstand ein. Maximale länge beträgt 15 Zeichen!",
            };
        }
        if (
            !personals?.civilStatus ||
            personals?.address?.civilStatus > 15 ||
            typeof personals?.civilStatus !== "string" ||
            !WHITELIST.test(personals?.civilStatus) ||
            !["ledig", "verheiratet", "geschieden", "verwidwet"].includes(personals?.civilStatus)
        ) {
            error["civilStatus"] = {
                error:
                    "Bitte geben Sie einen valide Zivilstand ein.",
            };
        }
        if (
            !personals?.hasChildren ||
            personals?.address?.hasChildren > 4 ||
            typeof personals?.hasChildren !== "string" ||
            !WHITELIST.test(personals?.hasChildren) ||
            !["ja", "nein"].includes(personals?.hasChildren)
        ) {
            error["hasChildren"] = {
                error:
                    "Bitte geben Sie einen valide Antwort ein.",
            };
        }
        if (personals?.civilStatus === "verheiratet") {
            error["partner"] = {};
            if (
                !personals?.partner?.firstname ||
                personals?.partner?.firstname?.length > 150 ||
                typeof personals?.partner?.firstname !== "string" ||
                !WHITELIST.test(personals?.partner?.firstname)
            ) {
                error["partner"] = {
                    ...error.partner,
                    firstname: {
                        error:
                            "Bitte geben Sie einen validen Vornamen ein. Maximale länge beträgt 150 Zeichen!",
                    }
                };
            }
            if (
                !personals?.partner?.lastname ||
                personals?.partner?.lastname?.length > 150 ||
                typeof personals?.partner?.lastname !== "string" ||
                !WHITELIST.test(personals?.partner?.lastname)
            ) {
                error["partner"] = {
                    ...error.partner,
                    lastname: {
                        error:
                            "Bitte geben Sie einen validen Nachnamen ein. Maximale länge beträgt 150 Zeichen!",
                    }
                };
            }
            if (
                !personals?.partner?.birthday ||
                personals?.partner?.birthday?.length > 12 ||
                !isValidDate(personals?.partner?.birthday) ||
                !WHITELIST.test(personals?.partner?.birthday)
            ) {
                error["partner"] = {
                    ...error.partner,
                    birthday: {
                        error:
                            "Bitte geben Sie ein valides Geburtdatum ein.",
                    }
                };
            }
            if (
                !personals?.partner?.profession ||
                personals?.partner?.profession?.length > 75 ||
                typeof personals?.partner?.profession !== "string" ||
                !WHITELIST.test(personals?.partner?.profession)
            ) {
                error["partner"] = {
                    ...error.partner,
                    profession: {
                        error:
                            "Bitte geben Sie einen validen Beruf ein. Maximale länge beträgt 75 Zeichen!",
                    }
                };
            }
        }

        if (personals?.hasChildren === "ja") {
            personals?.children.map((child, index) => {
                if (!error.children) {
                    error["children"] = [];
                }
                if (
                    !child?.fullName ||
                    child?.fullName?.length > 150 ||
                    typeof child?.fullName !== "string" ||
                    !WHITELIST.test(child?.fullName)
                ) {
                    error.children[index] = {
                        ...error.children[index],
                        fullName: {
                            error:
                                "Bitte geben Sie einen validen Vornamen ein. Maximale länge beträgt 150 Zeichen!",
                        }
                    };
                }
                if (
                    !personals?.partner?.birthday ||
                    personals?.partner?.birthday?.length > 12 ||
                    !isValidDate(personals?.partner?.birthday) ||
                    !WHITELIST.test(personals?.partner?.birthday)
                ) {
                    error.children[index] = {
                        ...error.children[index],
                        birthday: {
                            error:
                                "Bitte geben Sie ein valides Geburtdatum ein.",
                        }
                    };
                }
                if (
                    !child?.inApprenticeship ||
                    child?.inApprenticeship?.length > 4 ||
                    typeof child?.inApprenticeship !== "string" ||
                    !WHITELIST.test(child?.inApprenticeship) ||
                    !["ja", "nein"].includes(child?.inApprenticeship)
                ) {
                    error.children[index] = {
                        ...error.children[index],
                        inApprenticeship: {
                            error:
                                "Bitte geben Sie einen validen Antwort ein.",
                        }
                    };
                }
                if (child?.inApprenticeship === "ja") {
                    if (
                        !child?.endOfApprenticeship ||
                        child?.endOfApprenticeship?.length > 15 ||
                        typeof child?.endOfApprenticeship !== "string" ||
                        !WHITELIST.test(child?.endOfApprenticeship)
                    ) {
                        error.children[index] = {
                            ...error.children[index],
                            endOfApprenticeship: {
                                error:
                                    "Bitte geben Sie einen validen Antwort ein.",
                            }
                        };
                    }
                    if (
                        !child?.businessNameAndPlace ||
                        child?.businessNameAndPlace?.length > 100 ||
                        typeof child?.businessNameAndPlace !== "string" ||
                        !WHITELIST.test(child?.businessNameAndPlace)
                    ) {
                        error.children[index] = {
                            ...error.children[index],
                            businessNameAndPlace: {
                                error:
                                    "Bitte geben Sie einen validen Antwort ein. Maximale länge beträgt 100 Zeichen!",
                            }
                        };
                    }
                }
            })
        }

        if (Object.keys(error).length === 0) {
            let formData = new FormData();
            if (
                (personals.workStatus === "arbeitnehmer" ? (wageStatements && wageStatements.length > 0) : true) &&
                (personals.workStatus === "selbststaendig" ? (accounting && accounting.length > 0) : true) &&
                (personals.workStatus === "pensioniert" ? (pensionStatements && pensionStatements.length > 0) : true) &&
                securitiesStatements && securitiesStatements.length > 0 &&
                voluntaryProvision && voluntaryProvision.length > 0 &&
                donationStatements && donationStatements.length > 0
            ) {
                let arr = personals.workStatus === "arbeitnehmer" ? [...wageStatements] : personals.workStatus === "selbststaendig" ? [...accounting] : [...pensionStatements];
                arr.push(...donationStatements, ...voluntaryProvision, ...securitiesStatements)
                arr.forEach(media => {
                    formData.append("files", media);
                });
            }
            formData.append("formular", JSON.stringify({ ...personals, ...files }));
            ApiClient.post(`tax`, formData, { headers: { 'Content-type': 'multipart/form-data' } }).then(() => {
                setIsSendingSuccess(true)
            }).catch((err) => {
                if (err.data) setErrors(err.data)
                setIsSending(false)
                addToast("Das Formular konnte nicht gesendet werden!", { appearance: 'error' });
            })
        } else {
            setIsSending(false);
            setErrors({
                ...error,
            })
        }
    }

    return <div className="formular container">
        {isSending && <div className="formular-sending">
            {isSendingSuccess ? <>
                <CheckMarkSuccess />
                <span>Erfolgreich gesendet!</span>
            </> : <Loading />}
        </div>}
        <div className="formular-main">
            <div className="formular-progress">
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={progress}>
                        {steps.map((label) => {
                            const stepProps = {};
                            const labelProps = {};
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </div>
            <div className="formular-form-container">
                {progress === 0 ? <>
                    <h1>Geben Sie bitte Ihre Personalien ein</h1>
                    <Personals setErrors={setErrors} errors={errors} setPersonals={setPersonals} personals={personals} disabled={false} />
                </> : progress === 1 ? <>
                    <h1>Laden Sie alle von uns benötigen Dokumente hoch.</h1>
                    <div className="formular-form-files">
                        <div className="file-form-container">
                            <div className="income">
                                <h2 id="income">Für Angaben von Einkommen und Vermögen</h2>
                                <div className='income-body'>
                                    {personals.workStatus === "arbeitnehmer" && <FileDrop
                                        label="Lohnausweis(e) (für Arbeitnehmende)"
                                        name="wageStatements"
                                        allowedFormats={["application/pdf"]}
                                        setFiles={setWageStatements}
                                        files={wageStatements}
                                        maxFiles={2}
                                        maxSize={2_000_000}
                                    />}
                                    {personals.workStatus === "selbststaendig" && <FileDrop
                                        label="Buchhaltung (Für Selbstständige)"
                                        name="accounting"
                                        allowedFormats={["application/pdf"]}
                                        setFiles={setAccounting}
                                        files={accounting}
                                        maxFiles={2}
                                        maxSize={10_000_000}
                                    />}
                                    {personals.workStatus === "pensioniert" && <FileDrop
                                        label="Belege für Renten (für Pensionierte u.a.)"
                                        name="pensionStatements"
                                        allowedFormats={["application/pdf", "image/jpeg", "image/jpg", "image/png"]}
                                        setFiles={setPensionStatements}
                                        files={pensionStatements}
                                        maxFiles={2}
                                        maxSize={5_000_000}
                                    />}
                                    <FileDrop
                                        label="Kontoauszüge der Bank oder Post (letzte 12 Monate)"
                                        name="bankStatements"
                                        allowedFormats={["application/pdf"]}
                                        setFiles={setBankStatements}
                                        files={bankStatements}
                                        maxFiles={3}
                                        maxSize={5_000_000}
                                    />
                                    <FileDrop
                                        label="Belege zu Wertschriften"
                                        name="securitiesStatements"
                                        allowedFormats={["application/pdf", "image/jpeg", "image/jpg", "image/png"]}
                                        setFiles={setSecuritiesStatements}
                                        files={securitiesStatements}
                                        maxFiles={2}
                                        maxSize={5_000_000}
                                    />
                                </div>
                            </div>
                            <div className="deductions">
                                <h2 id="deductions">Für die Abzüge</h2>
                                <div className='deductions-body'>
                                    <FileDrop
                                        label="Belege der Beiträge in die Säule 3a (freiwillige Vorsorge)"
                                        name="voluntaryProvision"
                                        allowedFormats={["application/pdf"]}
                                        setFiles={setVoluntaryProvision}
                                        files={voluntaryProvision}
                                        maxFiles={2}
                                        maxSize={5_000_000}
                                    />
                                    <FileDrop
                                        label="Spendenbelege"
                                        name="donationStatements"
                                        allowedFormats={["application/pdf"]}
                                        setFiles={setDonationStatements}
                                        files={donationStatements}
                                        maxFiles={2}
                                        maxSize={5_000_000}
                                    />
                                    <FileDrop
                                        label="Für Wohneigentümer: alle Unterlagen zu Liegenschaftssteuer, Schuldzinsen, Rechnungen für Unterhalts- und Renovationsarbeiten, Betriebs- und Verwaltungskosten usw."
                                        name="homeownerStatements"
                                        allowedFormats={["application/pdf"]}
                                        setFiles={setHomeownerStatements}
                                        files={homeownerStatements}
                                        maxFiles={5}
                                        maxSize={20_000_000}
                                    />
                                    <Input
                                        label="Krankheitskosten"
                                        type="number"
                                        value={files?.medicalCosts}
                                        onChange={(e) => setFiles({ ...files, medicalCosts: e.target.value })}
                                    />
                                    <Input
                                        label="Berufskosten"
                                        type="number"
                                        value={files?.professionalCosts}
                                        onChange={(e) => setFiles({ ...files, professionalCosts: e.target.value })}
                                    />
                                    <Input
                                        label="Weiterbildung- und Umschulungsskosten"
                                        type="number"
                                        value={files?.trainingAndRetrainingCosts}
                                        onChange={(e) => setFiles({ ...files, trainingAndRetrainingCosts: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <>
                    <h1>Überprüfen Sie alle Daten</h1>
                    <Personals setErrors={setErrors} errors={errors} setPersonals={setPersonals} personals={personals} disabled />
                </>}
            </div>
        </div>
        <div className="formular-button-container">
            <div className="formular-button-body">
                {progress > 0 ? <button onClick={() => setProgress(progress - 1)} disabled={progress === 0}>Zurück</button> : <div />}
                {progress < 2 && <button onClick={() => setProgress(progress + 1)} disabled={getDisableNext()}>Weiter</button>}
                {progress === 2 && <button onClick={handleSend}>Absenden</button>}
            </div>
        </div>
    </div >
}

export default Formular;