import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Footer, Header, ScrollToTop } from './components';
import { Home, Formular } from './pages';
import { ToastProvider } from "react-toast-notifications";
import './styles/App.css';

export function Router() {
  return (
    <BrowserRouter>
      <ToastProvider
        autoDismiss="true"
        autoDismissTimeout={3000}
        placement="bottom-center"
      >
        <ScrollToTop>
          <Routes>
            <Route
              path="/"
              exact
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />
            <Route
              path="/tax"
              exact
              element={
                <>
                  <Header />
                  <Formular />
                </>
              }
            />
            {/* <Route
              path="/contact"
              exact
              element={
                <>
                  <Header />
                  <Contact />
                </>
              }
            />
            <Route
              path="/about"
              exact
              element={
                <>
                  <Header />
                  <About />
                </>
              }
            /> */}
          </Routes>
        </ScrollToTop>
      </ToastProvider>
    </BrowserRouter>

  );
}

export default Router;
