import Input from "./Input";
import Select from "./Select";

export function Personals({ setErrors, errors, personals, setPersonals, disabled }) {
    return <div className="formular-form-personal">
        <Input
            label="Vorname"
            type="text"
            value={personals?.firstname}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, firstname: e.target.value })}
            error={errors?.firstname?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Nachname"
            type="text"
            value={personals?.lastname}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, lastname: e.target.value })}
            error={errors?.lastname?.error}
            required
            disabled={disabled}
        />
        <Input
            label="E-Mail-Adresse"
            type="email"
            value={personals?.email}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, email: e.target.value })}
            error={errors?.email?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Geburtsdatum"
            type="date"
            value={personals?.birthday}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, birthday: e.target.value })}
            error={errors?.birthday?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Telefonnummer"
            type="text"
            value={personals?.phoneNumber}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, phoneNumber: e.target.value })}
            error={errors?.phoneNumber?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Adresse und Hausnummer"
            type="text"
            value={personals?.address}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, address: e.target.value })}
            error={errors?.address?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Kanton"
            type="text"
            value={personals?.canton}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, canton: e.target.value })}
            error={errors?.canton?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Ort"
            type="text"
            value={personals?.place}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, place: e.target.value })}
            error={errors?.place?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Postleitzahl"
            type="number"
            value={personals?.postalCode}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, postalCode: e.target.value })}
            error={errors?.postalCode?.error}
            required
            disabled={disabled}
        />
        <Input
            label="Beruf"
            type="text"
            value={personals?.profession}
            onChange={(e) => disabled ? null : setPersonals({ ...personals, profession: e.target.value })}
            error={errors?.profession?.error}
            required
            disabled={disabled}
        />
        {
            disabled ? <Input
                label="Arbeitsstand"
                type="text"
                value={personals?.workStatus}
                error={errors?.workStatus?.error}
                required
                disabled
            /> : <Select
                label="Arbeitsstand"
                value={personals?.workStatus}
                onChange={(e) => disabled ? null : setPersonals({ ...personals, workStatus: e.target.value })}
                error={errors?.workStatus?.error}
                required
            >
                <option value="arbeitnehmer">Arbeitnehmer</option>
                <option value="selbststaendig">Selbstständig</option>
                <option value="pensioniert">Pensioniert</option>
            </Select>
        }

        {
            disabled ? <Input
                label="Zivilstand"
                type="text"
                value={personals?.civilStatus}
                error={errors?.civilStatus?.error}
                required
                disabled
            /> : <Select
                label="Zivilstand"
                value={personals?.civilStatus}
                onChange={(e) => disabled ? null : setPersonals({ ...personals, civilStatus: e.target.value })}
                error={errors?.civilStatus?.error}
                required
            >
                <option value="ledig">Ledig</option>
                <option value="verheiratet">Verheiratet</option>
                <option value="geschieden">Geschieden</option>
                <option value="verwidwet">Verwidwet</option>
            </Select>
        }
        {
            personals?.civilStatus === "verheiratet" ? <>
                <Input
                    label="Vorname von Ihrem Partner"
                    type="text"
                    value={personals?.partner?.firstname}
                    onChange={(e) => disabled ? null : setPersonals({ ...personals, partner: { ...personals.partner, firstname: e.target.value } })}
                    error={errors?.partner?.firstname?.error}
                    required
                    disabled={disabled}
                />
                <Input
                    label="Nachname von Ihrem Partner"
                    type="text"
                    value={personals?.partner?.lastname}
                    onChange={(e) => disabled ? null : setPersonals({ ...personals, partner: { ...personals.partner, lastname: e.target.value } })}
                    error={errors?.partner?.lastname?.error}
                    required
                    disabled={disabled}
                />
                <Input
                    label="Geburtsdatum von Ihrem Partner"
                    type="date"
                    value={personals?.partner?.birthday}
                    onChange={(e) => disabled ? null : setPersonals({ ...personals, partner: { ...personals.partner, birthday: e.target.value } })}
                    error={errors?.partner?.birthday?.error}
                    required
                    disabled={disabled}
                />
                <Input
                    label="Beruf von Ihrem Partner"
                    type="text"
                    value={personals?.partner?.profession}
                    onChange={(e) => disabled ? null : setPersonals({ ...personals, partner: { ...personals.partner, profession: e.target.value } })}
                    error={errors?.partner?.profession?.error}
                    required
                    disabled={disabled}
                />
            </> : null
        }
        {
            disabled ?
                <Input
                    label="Haben Sie Kinder unter 18 Jahren oder Kinder die in der Ausbildung sind und noch zu Hause wohnen?"
                    value={personals?.hasChildren}
                    error={errors?.hasChildren?.error}
                    type="text"
                    required
                    disabled
                />
                :
                <Select
                    label="Haben Sie Kinder unter 18 Jahren oder Kinder die in der Ausbildung sind und noch zu Hause wohnen?"
                    value={personals?.hasChildren}
                    onChange={(e) => disabled ? null : setPersonals({ ...personals, hasChildren: e.target.value })}
                    error={errors?.hasChildren?.error}
                    required
                >
                    <option value="ja">Ja</option>
                    <option value="nein">Nein</option>
                </Select>
        }
        {
            personals?.hasChildren === "ja" && <>
                {
                    personals.children.map((_, index) =>
                        <div key={`Child-${index}`} className="child">
                            {
                                !disabled && index !== 0 &&
                                <button onClick={() => {
                                    let temp = { ...personals };
                                    temp.children.splice(index, 1);
                                    setPersonals(temp)
                                }} className="remove-child">- Kind entfernen</button>
                            }
                            <h2>Kind #{index + 1}</h2>
                            <Input
                                label="Vorname und Nachname"
                                type="text"
                                value={personals?.children[index]?.fullName}
                                onChange={(e) => {
                                    if (!disabled) {
                                        let temp = { ...personals };
                                        temp.children[index].fullName = e.target.value;
                                        setPersonals(temp)
                                    }
                                }}
                                error={errors?.children[index]?.fullName?.error}
                                required
                                disabled={disabled}
                            />
                            <Input
                                label="Geburtsdatum"
                                type="date"
                                value={personals?.children[index]?.birthday}
                                onChange={(e) => {
                                    if (!disabled) {
                                        let temp = { ...personals };
                                        temp.children[index].birthday = e.target.value;
                                        setPersonals(temp)
                                    }
                                }}
                                error={errors?.children[index]?.birthday?.error}
                                required
                                disabled={disabled}
                            />
                            {
                                disabled ?
                                    <Input
                                        label="Kind in Ausbildung?"
                                        type="text"
                                        value={personals?.children[index]?.inApprenticeship}
                                        error={errors?.children[index]?.inApprenticeship?.error}
                                        required
                                        disabled
                                    />
                                    :
                                    <Select
                                        label="Kind in Ausbildung?"
                                        value={personals?.children[index]?.inApprenticeship}
                                        onChange={(e) => {
                                            if (!disabled) {
                                                let temp = { ...personals };
                                                temp.children[index].inApprenticeship = e.target.value;
                                                setPersonals(temp)
                                            }
                                        }}
                                        error={errors?.children[index]?.inApprenticeship?.error}
                                        required
                                    >
                                        <option value="ja">Ja</option>
                                        <option value="nein">Nein</option>
                                    </Select>
                            }
                            {
                                personals?.children[index]?.inApprenticeship === "ja" && <>
                                    <Input
                                        label="Lehrfirma: Name und Ort"
                                        type="text"
                                        value={personals?.children[index]?.businessNameAndPlace}
                                        onChange={(e) => {
                                            if (!disabled) {
                                                let temp = { ...personals };
                                                temp.children[index].businessNameAndPlace = e.target.value;
                                                setPersonals(temp)
                                            }
                                        }}
                                        error={errors?.children[index]?.businessNameAndPlace?.error}
                                        required
                                        disabled={disabled}
                                    />
                                    <Input
                                        label="Enddatum der Lehre"
                                        type="text"
                                        value={personals?.children[index]?.endOfApprenticeship}
                                        onChange={(e) => {
                                            if (!disabled) {
                                                let temp = { ...personals };
                                                temp.children[index].endOfApprenticeship = e.target.value;
                                                setPersonals(temp)
                                            }
                                        }}
                                        error={errors?.children[index]?.endOfApprenticeship?.error}
                                        required
                                        disabled={disabled}
                                    />
                                </>
                            }
                        </div>)
                }
                {!disabled &&
                    <button
                        className='add-child'
                        onClick={() => {
                            let temp = { ...personals };
                            temp.children.push({
                                fullName: "",
                                birthday: "",
                                inApprenticeship: "nein",
                            })
                            setPersonals(temp)
                        }}
                    >
                        + Kind hinzufügen
                    </button>}
            </>
        }
    </div>
}