import '../styles/Input.css';

export function Select({ label, value, onChange, children, required }) {
    return (
        <div className="form-container">
            <label>{label}</label>
            <select
                value={value}
                onChange={onChange}
                required={required}
            >
                {children}
            </select>
        </div>
    );
}

export default Select;