import '../styles/CheckMarkSuccess.css';

export default function CheckMarkSuccess() {
    return <div className="animated-checkmark-success">
        <div className="checkmark-success-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="114px" height="114px">
                <g fill="none" stroke="#22AE73" stroke-width="3">
                    <circle cx="57" cy="57" r="52" style={{ strokeDasharray: '480px, 480px', strokeDashoffset: 960 }}></circle>
                    <polyline class="st0" stroke="#22AE73" stroke-width="3" points="31,60.8 48.7,76.9 80.2,40.4 " style={{ strokeDasharray: '100px, 100px', strokeDashoffset: 200 }} />
                </g>
            </svg>
        </div>
    </div>
}