import axios from 'axios';

const baseURL = "http://localhost:3001/api/v1/" || process.env.REACT_APP_BACKEND_URL;

const request = axios.create({ baseURL });

async function injectCredentials(request) {
    return request;
}

function successReturnData(response) {
    return response.data;
}

function logError(error) {
    return Promise.reject(error.response);
}

request.interceptors.request.use(request => injectCredentials(request));
request.interceptors.response.use(response => successReturnData(response), error => logError(error));

export default request;