import { useState } from "react";
import Input from "./Input";
import { FiSend } from 'react-icons/fi';
import { WHITELIST } from "../constants/whitelist";
import { useToasts } from "react-toast-notifications";
import { ApiClient, Loading } from '../components';
import '../styles/Contact.css';

export default function Contact() {
    const [user, setUser] = useState({
        fullName: '',
        email: '',
        message: '',
    });
    const [error, setError] = useState();
    const [isSending, setIsSending] = useState(false);

    let { addToast } = useToasts();

    function handleSend() {
        setIsSending(true);
        let errors = {};
        setError();
        if (
            user.fullName === "" ||
            user.fullName.length > 150 ||
            user.fullName.length === 0 ||
            !isNaN(user.fullName) ||
            !WHITELIST.test(user.fullName)
        ) {
            errors = {
                ...errors,
                fullName: {
                    error: "Bitte geben Sie einen valide Vor- und Nachnamen ein. Maximale länge beträgt 150 Zeichen!",
                },
            };
        }
        if (
            user.email === "" ||
            user.email.length > 150 ||
            user.email.length === 0 ||
            !isNaN(user.email) ||
            !WHITELIST.test(user.email) ||
            !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)
        ) {
            errors = {
                ...errors,
                email: {
                    error: "Bitte geben Sie eine valide E-Mail-Adresse ein. Maximale länge beträgt 150 Zeichen!",
                },
            };
        }
        if (
            user.message === "" ||
            user.message.length > 1000 ||
            user.message.length === 0 ||
            !isNaN(user.message) ||
            !WHITELIST.test(user.message)
        ) {
            errors = {
                ...errors,
                message: {
                    error: "Bitte geben Sie eine valide Nachricht ein. Maximale länge beträgt 1000 Zeichen!",
                },
            };
        }

        setError({ ...errors });
        if (!errors || Object.keys(errors).length === 0) {
            ApiClient.post("contact", { ...user })
                .then(() => {
                    addToast("E-Mail erfolgreich gesendet!", { appearance: "success" });
                    setError();
                    setUser({
                        fullName: '',
                        email: '',
                        message: '',
                    });
                })
                .catch((err) => {
                    if (err?.data) {
                        setError(err.data);
                        addToast("E-Mail konnte nicht gesendet werden.", { appearance: "error" });
                    } else {
                        addToast("Etwas ist schiefgelaufen. Bitte versuchen Sie es später erneut.", { appearance: "error" });
                    }
                })
                .finally(() => setIsSending(false));
        } else setIsSending(false);
    }

    return <div id="contact" className="contact">
        <h2>Kontaktieren Sie uns!</h2>
        <Input
            label="Vor- und Nachname"
            type="text"
            error={error?.fullName?.error}
            value={user.fullName}
            onChange={(e) => setUser({ ...user, fullName: e.target.value })}
        />
        <Input
            label="E-Mail-Adresse"
            type="email"
            error={error?.email?.error}
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
        />
        <Input
            label="Nachricht"
            type="textarea"
            error={error?.message?.error}
            value={user.message}
            onChange={(e) => setUser({ ...user, message: e.target.value })}
        />
        <div className="contact-btn-container">
            <button onClick={handleSend} disabled={isSending || !user.fullName || !user.email || !user.message}>
                {isSending ? <span className="isSending">Sendet... <Loading /></span> : <span>Senden <FiSend /></span>}
            </button>
        </div>
    </div>
}