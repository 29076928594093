
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { VscFile } from 'react-icons/vsc';
import { useToasts } from 'react-toast-notifications';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { AiOutlineFileImage, AiOutlineFilePdf } from 'react-icons/ai';

import '../styles/FileDrop.css';

export function FileDrop({ label, name, setFiles, files, maxSize, maxFiles, allowedFormats }) {
    let { addToast } = useToasts();

    const onDrop = useCallback(acceptedFiles => {
        let violations = [];

        if (acceptedFiles.length > maxFiles) violations.push(`Maximale anzahl an Dateien beträgt ${maxFiles}!`);

        if (violations.length !== 0) {
            displayErrors(violations);
        } else {
            acceptedFiles.forEach(file => {
                if (!allowedFormats.includes(file.type)) violations.push(`Unerlaubter Dateityp ausgewählt! Bitte wählen Sie Dateien mit folgenden Endungen aus [ ${allowedFormats.join(", ")} ]`);
                if (file.size > maxSize) violations.push(`Datei: ${file.path} ist zu lang! Maximale länge einer Datei beträgt ${maxSize / 1_000_000}MB.`);
            });

            if (violations.length === 0) {
                setFiles([...acceptedFiles]);
            } else {
                displayErrors(violations);
            }
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        // acceptedFiles: [...allowedFormats],
        // accept: "image/png, image/jpg, image/jpeg, video/*",
        // maxFiles: maxFiles,
        // acceptedFiles: allowedFormats.join(", "),
        onDrop
    })

    function displayErrors(arr) {
        arr.forEach(error => {
            addToast(error, { appearance: 'error' });
        })
    }

    return (
        <div className="file-drop-form">
            <label>{label}</label>
            <div className="file-drop-container">
                {files?.length > 0 ?
                    <div className="selected-files">
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Pagination]}
                        >
                            {files.map((file) => (
                                <SwiperSlide>
                                    <div className="file-drop-file">
                                        {file.type.includes("image") ?
                                            <AiOutlineFileImage className="file-drop-file-icon" />
                                            : file.type.includes("pdf") ? <AiOutlineFilePdf className="file-drop-file-icon" /> : null}
                                        <span>{file.path}</span>
                                    </div>
                                </SwiperSlide>
                            )
                            )}
                        </Swiper>
                    </div>
                    : null}
                <div className='file-drop' {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ?
                        <p>Hier fallen lassen...</p> :
                        <div className="file-dropzone">
                            <VscFile className='form-file-icon' />
                            <p><u>Zum Hochladen klicken</u> oder ziehen und ablegen</p>
                        </div>}
                </div>
            </div>
            <span>Regeln: Dokumententyp [ {allowedFormats?.join(", ")} ] / Max. {maxFiles} Dokumente / Max. grösse {maxSize / 1_000_000}MB</span>
        </div>
    );
}

export default FileDrop;